import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import * as apiUtils from "../../common/utils/apiGateway";
import { notifyApiError } from "../../common/utils/utils";
import "./login.scss";
import { LsnModules } from "../../configs/permissionTags";
import { validationRules } from "../../common/utils/constants";
import cookie from "react-cookies";
import NumberFormat from "react-number-format";
import { Mixpanel } from "../../utils/mixpanel";
import moment from "moment";
import { useInterval } from "./customHookUseInterval";
import logo from "../../assets/img/logoBlue.png";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { enableOnlyGoogelAuth } from "../../environments/node-react-environment";

function login(props) {
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [otpError, setOtpError] = useState(undefined);
  // const [showCounter, setShowCounter] = useState(false)
  const [count, setCount] = useState(0);
  const [otpVal, setOtpVal] = useState(undefined);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [showOTPPanel, setShowOTPPanel] = useState(true);
  const [clickedResendOTP, setClickedResendOTP] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(
    atob(localStorage.getItem("application"))
  );
  const [nopermission, setNopermission] = useState(true);
  const [loginMethod, setLoginMethod] = useState("otp");
  const [redirectURL, setRedirectURL] = useState("");
  const [loginButtonDisable, setLoginButtonDisable] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [signInButtonDisabled, setSignInButtonDisabled] = useState(false);

  const { getFieldDecorator, getFieldsError, getFieldValue } = props.form;

  let otp = getFieldValue("otp");

  useEffect(() => {
    props.form.validateFields(["mobileno"]);

    if (!!localStorage.getItem("accessToken")) {
      props.history.push(getRedirectedUrl());
    }
  }, []);

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const checkHttpStatus = (status) => {
    switch (status?.code) {
      case 403:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 400:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 401:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 500:
      case 503:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 200:
      case 201:
      case 202:
        return true;
      default:
        break;
    }
  };

  const getAllPersmission = () => {
    const permissions = localStorage.getItem("permissions");
    if (!permissions) {
      return null;
    }
    let selectedApplication = atob(localStorage.getItem("application"));
    setSelectedApplication(selectedApplication);
    console.log("102 " + selectedApplication);
    return JSON.parse(atob(permissions));
  };

  const store = (permissions) => {
    if (permissions == null) {
      return null;
    }
    localStorage.setItem("permissions", btoa(JSON.stringify(permissions)));
    var _permissions = getAllPersmission();
    const allApplications = Object.keys(_permissions);
    let selectedApplication = allApplications[0];
    setSelectedApplication(selectedApplication);
    localStorage.setItem("application", btoa(selectedApplication));
    console.log("113 " + selectedApplication);
  };

  const parsePermission = (permissions) => {
    if (!permissions) {
      return null;
    }
    const pObj = {};
    permissions.forEach((permission) => {
      if (!pObj[permission.application]) {
        pObj[permission.application] = {};
      }

      if (!pObj[permission.application][permission.module]) {
        pObj[permission.application][permission.module] = { permissions: [] };
      }

      if (permission.submodule == null) {
        pObj[permission.application][permission.module].permissions.push(
          permission.action
        );
      } else {
        console.log("136 pObj " + pObj);
        if (
          !pObj[permission.application][permission.module][permission.submodule]
        ) {
          pObj[permission.application][permission.module][
            permission.submodule
          ] = { permissions: [] };
          pObj[permission.application][permission.module][
            permission.submodule
          ] = { permissions: [] };
        }
        pObj[permission.application][permission.module][
          permission.submodule
        ].permissions.push(permission.action);
      }
    });
    return pObj;
  };

  const getRedirectedUrl = () => {
    const permissions = getAllPersmission();
    // console.log("permissions " + permissions);
    const application = atob(localStorage.getItem("application"));
    const pages = Object.keys(permissions[application]);
    console.log("160 " + pages);
    const redirectionOrder = {
      TRACKING: "tracking",
      SETTINGS: "settings",
    };
    let url = "";
    Object.keys(redirectionOrder).forEach((order) => {
      if (url === "" && pages.indexOf(order) !== -1) {
        url = redirectionOrder[order];
      }
    });
    if (url !== "") {
      url = url + "/dashboard";
    } else {
      url = "authentication/login";
    }
    localStorage.setItem("defaultUrl", url);
    return url;
  };

  const handleLogin = (response) => {
    console.log(response);
    let _response = response;
    Mixpanel.identify(response?.data?.response?.user?.id);
    Mixpanel.people.set({
      $first_name: response?.data?.response?.user?.name,
      $email: response?.data?.response?.user?.email,
      $phone_number: response?.data?.response?.user?.number,
      $customer_id: response?.data?.response?.user?.location?.entity?.id,
      $customer_name:
        response?.data?.response?.user?.location?.entity?.custObject?.name,
    });
    Mixpanel.track("Password Login Successfully done", {
      loginIdentity: moment().valueOf(),
    });
    if (checkHttpStatus(response?.data?.status)) {
      const response = _response.data.response;
      console.log("227 " + response);

      //----Going into infinite loop. Need to look later
      // for (let i = 0; i <= permissionData.length; i++) {
      //   if (permissionData[i]) {
      //     if (modulenames.includes(permissionData[i]["module"])) {
      //       setNopermission(true)
      //       break;
      //     }
      //   }
      // }

      if (!nopermission) {
        localStorage.setItem("isSassProduct", false);
      }

      // if (Constants.ENV === "local") {
      //   const data = {
      //     accessToken: response.token.accessToken,
      //     refreshToken: response.token.refreshToken,
      //     tokenId: response.token.tokenId,
      //     user: btoa(JSON.stringify(response.user)),
      //     permissions: btoa(
      //       JSON.stringify(
      //         this.parsePermission(response.user.permissions)
      //       )
      //     ),
      //     application: btoa(
      //       this.getApplicationState(response.user.permissions)
      //     ),
      //     loginMethod: "oldLogin",
      //     isSassProduct: false,
      //   };
      //   // this.generateIndexFiles(data).then((res) => {
      //   //   // console.log("res->>", res)
      //   // });
      // }

      store(parsePermission(response.user.permissions));
      cookie.save("LOGGED_IN", true, { path: "/" });
      localStorage.setItem("primaryAccessToken", response.token.accessToken);
      localStorage.setItem("primaryTokenId", response.token.tokenId);
      localStorage.setItem("accessToken", response.token.accessToken);
      localStorage.setItem("refreshToken", response.token.refreshToken);
      localStorage.setItem("tokenId", response.token.tokenId);
      localStorage.setItem(
        "roleName",
        JSON.stringify(response.user.roles[0].name)
      );
      localStorage.setItem("user", btoa(JSON.stringify(response.user)));
      localStorage.setItem("refreshFlag", JSON.stringify(new Date().valueOf()));
      console.log("282 " + nopermission);
      if (!redirectURL) {
        let redirectURL = getRedirectedUrl();
        setRedirectURL(redirectURL);
        // props.history.push(redirectURL)
        window.location.href = redirectURL;
      }
      //----need to resolve nopermission
      // if (nopermission === false) {
      //   props.history.push("nopermission/dashboard");

      // } else {
      //   props.history.push(redirectURL)
      // }
    }
  };

  const handleOTPLogin = (e) => {
    e.preventDefault();
    setLoginButtonDisable(true);
    setSignInButtonDisabled(true);
    props.form.validateFields(["mobileno", "otp"], (err, values) => {
      if (!err) {
        let mobile = values.mobileno;
        let mobileNo = mobile.replace(/-/gi, "");
        let params = {
          otp: values.otp,
          number: mobileNo,
          callingSystem: "B2B",
        };
        apiUtils
          .apiRequest({
            method: "POST",
            url: "/v1/login/otp",
            data: params,
          })
          .then(handleLogin)
          .catch(() => {
            setLoginButtonDisable(false);
          });
      } else {
        setLoginButtonDisable(false);
      }
    });
  };

  const handleGoogleSignIn = (emailId) => {
    if (!!emailId) {
      apiUtils
        .apiRequest({
          method: "POST",
          url: "/v1/login/email",
          data: {
            email: emailId,
          },
        })
        .then(handleLogin)
        .catch(() => {
          setLoginButtonDisable(false);
        });
    }
  };

  const interval = useInterval(
    () => {
      if (seconds === 0) {
        setShowTimer(false);
        clearInterval(interval.current);
      } else {
        setSeconds((s) => s - 1);
      }
    },
    showTimer ? 1000 : null
  );

  useEffect(() => {
    return function clearup() {
      clearInterval(interval.current);
    };
  }, []);

  const submitOTP = () => {
    props.form.validateFields(["mobileno"], (err, values) => {
      if (!err) {
        // const { getFieldValue } = this.props.form;
        let mobile = values["mobileno"];
        let mobileNo = mobile.replace(/-/gi, "");
        apiUtils
          .apiRequest({
            method: "GET",
            url: `/v1/otp/${mobileNo}`,
            // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
          })
          .then((response) => {
            if (checkHttpStatus(response?.data?.status)) {
              if (response.data.status && response.data.status.code === 200) {
                setIsValidOTP(true);
                setShowTimer(true);
              }
            }
          });
      }
    });
  };

  const resendOTP = () => {
    let mobile = getFieldValue("mobileno");
    let mobileNo = mobile.replace(/-/gi, "");
    setCount((count) => count + 1);
    setOtpError(undefined);
    apiUtils
      .apiRequest({
        method: "GET",
        url: `/v1/otp/${mobileNo}`,
      })
      .then((response) => {
        if (checkHttpStatus(response?.data?.status)) {
          setShowTimer(false);
          if (response.data.status && response.data.status.code === 200) {
            setIsValidOTP(true);
            // setCount((count) => count + 1);
            setShowTimer(true);
            setClickedResendOTP(true);
            setSeconds(30);
          }
        } else {
          setOtpError(response.data.status.message);
        }
      });
  };

  const hideOTPPanel = () => {
    setShowOTPPanel(false);
    // setState({ showOTPPanel: false });
  };

  const otpChangehandler = (e) => {
    setOtpVal(e.target.value);
    setSignInButtonDisabled(false);
  };

  const showOnlyGoogleAuthLogin = enableOnlyGoogelAuth;
  const showOTPLogin = enableOnlyGoogelAuth;
  return (
    <>
      <div className="outermost-div-otp">
        <div className={isValidOTP ? "logo-valid-otp" : "outer-left-div-otp"}>
          <img className="logo-otp" src={logo} alt="logo"></img>
          {showOTPLogin && (
            <>
              <h1 id="title-opt">Get OTP</h1>
              <Form
                autoComplete="off"
                initialValues={{
                  remember: true,
                }}
                style={{ color: "blue" }}
              >
                <Form.Item
                  tab="OTP"
                  key="1"
                  name="otpLogin"
                  style={{ margin: "0px 0 15px", textAlign: "left" }}
                  rules={[{ required: true }]}
                >
                  {getFieldDecorator("mobileno", {
                    rules: [
                      {
                        pattern: validationRules.mobileWithRegex,
                        message: "Please enter valid mobile number",
                      },
                    ],
                  })(
                    <NumberFormat
                      disabled={isValidOTP}
                      format="###-###-####"
                      type="tel"
                      mask=" "
                      placeholder="Enter Your Mobile Number"
                      className="login-tabpane-otp"
                    />
                  )}
                </Form.Item>
                <span style={{ color: "red" }}>{otpError}</span>
                {!isValidOTP ? (
                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: "400px",
                        height: "40px",
                        flexGrow: "0",
                        padding: "9px 177px 9px 178px",
                        borderRadius: "4px",
                        backgroundImage:
                          "linear-gradient(to top, #1665d8 98%, #1f6fe5 2%)",
                      }}
                      disabled={isValidOTP || hasErrors(getFieldsError())}
                      onClick={submitOTP}
                    >
                      <span className="confirm-otp">Submit</span>
                    </Button>
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item
                      tab="OTP"
                      key="1"
                      name="otpLogin"
                      style={{ margin: "0px 0 15px", textAlign: "left" }}
                      rules={[{ required: true }]}
                    >
                      {getFieldDecorator(
                        "otp",
                        {}
                      )(
                        <NumberFormat
                          value={otp}
                          min={4}
                          // disabled={validOTP}
                          placeholder="Enter OTP Received"
                          className="login-tabpane-otp-received"
                          onChange={(e) => otpChangehandler(e)}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "400px",
                          height: "40px",
                          flexGrow: "0",
                          padding: "9px 177px 9px 178px",
                          borderRadius: "4px",
                          backgroundImage:
                            "linear-gradient(to top, #1665d8 98%, #1f6fe5 2%)",
                        }}
                        disabled={
                          signInButtonDisabled ||
                          !Boolean(otpVal) ||
                          hasErrors(getFieldsError())
                        }
                        onClick={handleOTPLogin}
                      >
                        <span className="confirm">SIGN IN</span>
                      </Button>
                      {showOTPPanel ? (
                        <div className="showOTPPanel">
                          {count <= 3 ? (
                            <>
                              {!showTimer ? (
                                <>
                                  <Button
                                    type="link"
                                    onClick={resendOTP}
                                    style={{
                                      float: "left",
                                      color: "#051c57",
                                      margin: "10px 0 0 -13px",
                                    }}
                                    disabled={count > 3}
                                  >
                                    {" "}
                                    Resend OTP{" "}
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <>
                                    <Button
                                      type="link"
                                      style={{
                                        float: "left",
                                        color: "gray",
                                        margin: "10px 0 0 -13px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Resend OTP: {minutes}:
                                      {seconds < 10 ? `0${seconds}` : seconds}
                                    </Button>
                                  </>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <span>Maximum Count exceeded</span>
                            </>
                          )}
                          {clickedResendOTP && (
                            <Button
                              type="link"
                              onClick={hideOTPPanel}
                              style={{
                                float: "right",
                                color: "rgb(5, 28, 87)",
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              {" "}
                              Get OTP on Call
                            </Button>
                          )}
                        </div>
                      ) : (
                        <>
                          <span style={{ color: "#1665d8" }}>
                            Please call on 08047096347 to get the latest OTP
                          </span>
                        </>
                      )}
                    </Form.Item>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#051c57",
                      fontSize: "14px",
                      fontWeight: "500",
                      marginBottom: "10px",
                    }}
                  >
                    OR
                  </span>
                </div>
              </Form>
            </>
          )}
          {showOnlyGoogleAuthLogin && (
            <>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <h4>Login with Google</h4>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    const { email } = jwtDecode(credentialResponse.credential);
                    handleGoogleSignIn(email);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="outer-right-div">
          {/* <img src={img} className="img" alt="img"></img> */}
        </div>
      </div>
    </>
  );
}

const LoginForm = Form.create({ name: "login" })(login);

export default LoginForm;
